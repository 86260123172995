<template>

    <!-- <keep-alive> -->
    <div id="enterSearch" class="list_content">
        <!-- 查询条件 -->
        <el-form v-if="qyzhShow" :inline="true" :model="formInline" class="demo-form-inline">

            <el-form-item>
                <el-button type="primary" @click="cx_sunbmit(1)">今天</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="cx_sunbmit(2)">一天前</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="cx_sunbmit(3)">二天前</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="cx_sunbmit(4)">三天前</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="cx_sunbmit(5)">四天前</el-button>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="cx_sunbmit(6)">重置</el-button>
            </el-form-item>
        </el-form>
        <!-- 列表内容 -->
        <el-table :data="tableData" :cell-style="{ padding: '2px 0' }" height="680" border stripe>
            <el-table-column label="关系" width="60" align="center">
                <template slot-scope="scope">
                    <el-button @click="toXs(scope.row)" type="text" size="small">列表</el-button>
                </template>
            </el-table-column>

            <el-table-column prop="user_name" label="单位名称" show-overflow-tooltip width="200"
                align="center"></el-table-column>
            <el-table-column prop="ks_type" label="科室类别" show-overflow-tooltip width="80"
                align="center"></el-table-column>

            <el-table-column prop="yunying_state" width="80" show-overflow-tooltip label="运营状态" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.yunying_state == 1">渠道</span>
                    <span v-if="scope.row.yunying_state == 2" style="color:green">自运营</span>
                    <span v-if="scope.row.yunying_state == 3" style="color:green">合作</span>
                    <span v-if="scope.row.yunying_state == 4" style="color:green">渠转自</span>
                    <span v-if="scope.row.yunying_state == 5">自转渠</span>
                </template>
            </el-table-column>

            <el-table-column prop="appearance_time" label="最新产出日期" show-overflow-tooltip width="180"
                align="center"></el-table-column>
            <el-table-column prop="today_count" label="今天" show-overflow-tooltip width="40"
                align="center"></el-table-column>
            <el-table-column prop="bf1_today_count" label="一天前" show-overflow-tooltip width="40"
                align="center"></el-table-column>
            <el-table-column prop="bf2_today_count" label="二天前" show-overflow-tooltip width="40"
                align="center"></el-table-column>
            <el-table-column prop="bf3_today_count" label="三天前" show-overflow-tooltip width="40"
                align="center"></el-table-column>
            <el-table-column prop="bf4_today_count" label="四天前" show-overflow-tooltip width="40"
                align="center"></el-table-column>

            <el-table-column prop="province_name" label="省份" show-overflow-tooltip width="120"
                align="center"></el-table-column>
            <el-table-column prop="city_name" label="城市" show-overflow-tooltip width="80"
                align="center"></el-table-column>
            <el-table-column prop="pay_num" width="80" show-overflow-tooltip label="充值条数"
                align="center"></el-table-column>
            <el-table-column prop="allot_num" width="80" show-overflow-tooltip label="分配条数"
                align="center"></el-table-column>
            <el-table-column prop="remain" width="80" show-overflow-tooltip label="剩余表单">
                <template slot-scope="scope">
                    <span v-if="scope.row.pay_num - scope.row.allot_num <= 5 && scope.row.pay_num - scope.row.allot_num > 0"
                        style="color:orange">{{ scope.row.pay_num - scope.row.allot_num }}</span>
                    <span v-else-if="scope.row.pay_num - scope.row.allot_num > 5"
                        style="color:#37B328">{{ scope.row.pay_num - scope.row.allot_num }}</span>
                    <span v-else-if="scope.row.pay_num - scope.row.allot_num == 0" style="color:red">{{ scope.row.pay_num
        - scope.row.allot_num
}}</span>
                </template>
            </el-table-column>

            <el-table-column v-if="qyzhShow" prop="use_status" label="用户状态" width="80" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.use_status == 1" style="color:#37B328">启用</span>
                    <span v-if="scope.row.use_status == 2" style="color:#37B328">禁用</span>
                </template>
            </el-table-column>

        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="current_page" :page-sizes="[15, 30, 50, 100, 200, 500]" :page-size="page_size"
            layout="total, sizes, prev, pager, next, jumper" :total="data_num" :sizes='page_num'>
        </el-pagination>
    </div>
</template>

<script>
import LeftMenu from '@/components/left.vue'
import Top from '@/components/top.vue'
import Footer from '@/components/footer.vue'

export default {
    name: 'GetEveryDayFormCountView',
    components: {
        LeftMenu,
        Top,
        Footer
    },
    data() {
        return {
            cache_user: {},
            formInline: {},
            tableData: [],
            current_page: 1, //初始页
            page_size: 15, // 每页的数据
            data_num: 0, // 总条数
            page_num: 0, // 总页数
            userList: [],
            show_liul: '',
            qyzhShow: true,
        }
    },

    created() {
        console.log('开始查询!')
        // 从缓存获取user
        this.cache_user = JSON.parse(sessionStorage.getItem('user'))
        this.onSubmit()

    },

    mounted() {
        // 监听回车键查询
        let that = this;
        let enterSearchEle = document.getElementById("enterSearch")

        enterSearchEle.addEventListener("keydown", function (e) {
            console.log(e);
            if (e.key == "Enter") {
                that.$xianliu(that.onSubmit, 500)()
            }
        })
    },

    methods: {
        cx_sunbmit(kind) {
            switch (kind) {
                case 1:
                    this.formInline.calc_today = 1
                    break;
                case 2:
                    this.formInline.calc_bf1_today = 1
                    break;
                case 3:
                    this.formInline.calc_bf2_today = 1
                    break;
                case 4:
                    this.formInline.calc_bf3_today = 1
                    break;
                case 5:
                    this.formInline.calc_bf4_today = 1
                    break;
                case 6:
                    this.formInline.calc_today = 0
                    this.formInline.calc_bf1_today = 0
                    this.formInline.calc_bf2_today = 0
                    this.formInline.calc_bf3_today = 0
                    this.formInline.calc_bf4_today = 0
                    break;
                default:
                    break;
            }

            this.current_page = 1
            this.onSubmit()
        },
        onSubmit() {
            console.log('开始查询!');
            const that = this

            that.formInline.current_page = Number(that.current_page)
            that.formInline.page_size = Number(that.page_size)
            if (that.cache_user.user_level != 1 && that.cache_user.user_level != 2) {
                that.formInline.user_id = that.cache_user.user_id
                that.qyzhShow = false
            }

            this.myAxios.post(that.myBaseurl + '/user/geteverydayformcount', that.formInline)
                .then(function (res) {
                    if (res.data.Code === '200') {
                        console.log("submit--res : ", res)
                        that.tableData = res.data.Data.List
                        that.data_num = res.data.Data.TotalCount
                        that.page_num = res.data.Data.TotalPage
                        for (let item of that.tableData) {
                            item.remain = item.pay_num - item.allot_num
                        }
                    } else {
                        alert("查询失败 : " + res.data.Msg)
                    }
                })
        },
        // 初始页current_page、初始每页数据数page_size和数据data
        handleSizeChange(size) {
            this.page_size = size;
            console.log("this.page_size : ", this.page_size) //每页下拉显示数据
            this.onSubmit()
        },
        handleCurrentChange(current_page) {
            this.current_page = current_page;
            console.log(this.current_page) //点击第几页
            this.onSubmit()
        },

        toXs: function (u) {
            // 加入缓存
            sessionStorage.setItem('sec_user', JSON.stringify(u))
            this.$router.push({
                path: '/form/list',
            })
        },

    },

}

</script>

<style lang="less" scoped="scoped">
body {
    margin: 0px;
    padding: 0px;
}

.el-main {
    height: calc(100vh - 120px);
}

.list_content {
    text-align: center;
    width: 100%;
}

.el-table-column {
    line-height: 20px;
}

.el-table__row .hover-row {
    background-color: red !important;
}

a {
    text-decoration: none;
}
</style>
